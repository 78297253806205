.sidebar {
  width: 370px;
  background-color: #fff;
  // overflow-x: hidden;
  transition: width 0.3s ease;
  position: relative;
  min-height: 95%;
  &.first-toggle {
    transition: none;
  }
}

.sidebar.collapsed {
  width: 60px;
  @media (max-width: 768px) {
    width: 2rem;
  }
}

.toggle-btn {
  position: absolute;
  z-index: 2;
  top: 0px;
  right: 0;
  // background-color: red;
}
.toggle-btn.collapsed {
  top: 0px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  @media (max-width: 768px) {
    left: 30%;
  }
  // background-color: red;
}

.sidebar-content {
  padding: 20px;
  border-right: 1px solid lightgray;
}

// @media (min-width: 1500px) and (max-width: 1700px) {
//   .sidebar {
//     width: 350px !important;
//   }
// }

// // Apply small screen font sizes
@media (min-width: 992px) and (max-width: 1500px) {
  .sidebar {
    width: 270px;
  }
}

// // Apply extra small screen font sizes
@media (max-width: 991px) {
  .sidebar {
    width: 250px;
  }
}


// // Apply mobile screen font sizes
// @media (max-width: 767px) {
// }
