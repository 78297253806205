@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import './roundedDropdown.scss';
@import './roundedInput.scss';

// Override Bootstrap primary color
$theme-colors: (
  'primary': #049fdb,
  // Replace with your desired color
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);

// Import the rest of Bootstrap
@import '~bootstrap/scss/bootstrap';

* {
  font-weight: 400;
}
.pv-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.tab_header {
  border-bottom-style: solid;
  border-bottom-color: $gray-200;
  border-bottom-width: 1px;
  margin-bottom: 15px;
}
.primary_button {
  background-color: #049fdb;
  border: 1px solid #049fdb;
  color: white;
  border-radius: 30px;
  width: auto;
  cursor: pointer;
  &:hover {
    background-color: white;
    border: 1px solid #049fdb;

    color: #049fdb;
  }
}
.nav-pills {
  .nav-link {
    border-style: solid;
    border-color: $gray-200;
    border-width: 1px;
    margin-left: 4px;
    margin-right: 4px;

    &:hover {
      color: $primary !important;
    }

    &.active {
      background: $primary;

      &:hover {
        color: $white !important;
      }
    }
  }
}

.ui-theme-settings {
  position: fixed;
  z-index: 10;

  .btn-open-options {
    @include border-radius(50px);
    position: absolute;
    left: -114px;
    bottom: 7px;
    padding: 0;
    height: 45px;
    line-height: 45px;
    width: 45px;
    text-align: center;
    display: block;
    box-shadow: $box-shadow-default;
    margin-top: -27px;
  }
}

.user-avatar {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
  background-size: cover;
  background-position: center center;
}

.form-header-icon {
  margin: 0px 10px;
  color: $primary !important;
  font-size: 15px;
  cursor: pointer;
}

svg:not(:root).svg-inline--fa {
  // vertical-align: -1.125em;
}

.primary .rx-editor {
  background-color: $primary;
}

.secondary .rx-editor {
  background-color: $secondary;
}

.success .rx-editor {
  background-color: $success;
}

.info .rx-editor {
  background-color: $info;
}

.warning .rx-editor {
  background-color: $warning;
}

.danger .rx-editor {
  background-color: $danger;
}

.light .rx-editor {
  background-color: $light;
}

.dark .rx-editor {
  background-color: $dark;
}

.table-column-search {
  .form-group {
    margin-bottom: 0;

    .form-control {
      // height: 29px;
      font-size: 15px;
    }
  }
}

.table-top-info {
  padding: 15px 0;
}

// edit page cms

.dropdown-form {
  padding: 10px;
}

.edit-top-nav-button {
  position: absolute;
  top: 1px;
  right: 0;
  z-index: 111;

  i {
    font-size: 17px !important;
  }
}

.edit-top-nav-page {
  position: absolute;
  top: 1px;
  z-index: 111;
  right: 50px;

  i {
    font-size: 17px !important;
  }
}

.widget-content-left .remove-button {
  position: absolute;
  top: -5px;
  right: 0;
  border-radius: 55%;
  width: 13px;
  height: 13px;
  padding: 0;
  min-width: inherit;
  font-size: 11px;
  cursor: pointer;
}

.auto-complete {
  position: absolute;
  top: 68px;
  width: 88%;
  z-index: 11111;

  .list-group-item {
    cursor: pointer;
  }
}

.select-color {
  background: transparent;
  height: 20px;
  width: 20px;
  float: right;
  margin-left: 11px;
  cursor: pointer;
  position: relative;
  outline: none;
  transition: box-shadow 100ms ease 0s;
  border-radius: 50%;
}

.pointer {
  cursor: pointer;
}

.edit-form-page-button {
  top: 15px;
  margin-bottom: 20px;
}

.sidebar-nav-edit-button {
  text-align: center;
  margin: 10px;
}

.font-10rem {
  font-size: 10rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.widget-content-left .dropdown-menu.dropdown-menu-right {
  transform: translate3d(-758px, 46px, 0px) !important;
}

.header-btn-lg .widget-content-left .dropdown-menu.dropdown-menu-xl {
  transform: translate3d(-758px, 46px, 0px) !important;
}

.app-header-right .dropdown-menu.dropdown-menu-xl {
  min-width: 50rem;
}

// table
.table-input {
  border-width: 0;
  background-color: transparent;
  width: 100%;
}
input.table-input:read-only {
  outline: none !important;
}

.logo-src-name {
  color: rgb(25, 118, 210);
  font-size: 1.5rem;
}

.closed-sidebar {
  .logo-src-container {
    display: none;
  }
}
.react-datetimerange-picker__calendar {
  z-index: 20 !important;
}

.logo-container-edit-form {
  display: flex;
  flex-direction: column;
}
.basic-title {
  color: #6c757d;
  margin: 0 0 3px;
  padding-left: 1em;
  font-weight: 600;
  font-size: 15px;
}
.field-section {
  background: aliceblue;
  border-bottom-width: 0px;
}

.theme_auth_button {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-top: 1.8rem;
  margin-bottom: 1rem;
}

.sidebar-text-dark {
  color: rgba(0, 0, 0, 0.6);
}
tr.sidebar-text-light {
  border: 0.5px solid;
}
.sidebar-text-light {
  color: rgba(255, 255, 255, 0.9);

  input {
    color: rgba(255, 255, 255, 0.9);
  }
  .select__single-value {
    color: rgba(255, 255, 255, 0.9);
  }
}

.react-tabs__tab--selected {
  color: #049FDB !important;
  border: none !important;
  border-bottom: 2px solid #049FDB !important;
}
.react-tabs__tab--selected:focus {
  border: none !important;
  border-bottom: 2px solid #049FDB !important;
}
.react-tabs__tab {
  position: unset !important;
}

.header-filter-date {
  color: inherit;
  width: 100%;
  border: 1px solid #ced4da;
  // font-size: 15px;
  padding: 0.4rem 0.75rem;
  border-radius: 0.25rem;
}

.tableForm {
  flex: 1;
  .table-date {
    background: transparent;
    border: 0px;
    color: inherit;
    width: 100%;
  }
  .table-date-read-only:focus {
    border: none;
  }
  .table-date-read-only:read-only {
    outline: none;
  }

  .controlSelect {
    background-color: transparent;
    border: none;
    min-height: fit-content;
  }

  .form-control {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .select {
    &__control {
      background-color: transparent;
      border: none;
      min-height: fit-content;
    }
    &__option {
      color: rgba(0, 0, 0, 0.6);
    }
    &__option--is-focused {
      // color: rgba(255, 255, 255, 0.9);
    }
    &__indicator {
      display: none;
    }
    &__indicators {
      display: none;
    }
    &__indicator-seperator {
      display: none;
    }
    &__single-value--is-disabled {
      color: black !important;
    }
    &__placeholder {
      color: hsl(0, 0%, 70%) !important;
    }
  }
}

.tableFilter {
  flex: 1;
  .table-date {
    background: transparent;
    border: 0px;
    color: inherit;
    width: 100%;
  }
  .table-date-read-only:focus {
    border: none;
  }
  .table-date-read-only:read-only {
    outline: none;
  }

  .controlSelect {
    background-color: transparent;
    border: none;
    min-height: fit-content;
  }

  .form-control {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .select {
    &__control {
      background-color: #fff;
      border: none;
      min-height: fit-content;
      font-size: 14px;
      font-weight: 400;
      border: 1px solid #ced4da;
    }
    &__option {
      color: rgba(0, 0, 0, 0.6);
    }
    &__option--is-focused {
      // color: rgba(255, 255, 255, 0.9);
    }
    &__indicator {
      display: none;
    }
    &__indicators {
      display: none;
    }
    &__indicator-seperator {
      display: none;
    }
    &__single-value--is-disabled {
      color: black !important;
    }
    &__placeholder {
      color: hsl(0, 0%, 70%) !important;
    }
  }
}
.switch-icon {
  font-size: 14px;
  text-align: center;
  padding: 7px 11px;
  color: #fff;
  font-weight: 500;
}

.gridSettigTable {
  i {
    cursor: pointer;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
}

.gridFilterTable {
  i {
    cursor: pointer;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
}

.select--is-disabled {
  color: black;
}
@media print {
  @page {
    size: A4;
    margin: 0;
  }

  html,
  body {
    width: 210mm; // A4 Paper width
    height: 90%;
  }
  .app-main__outer {
    padding-left: 0 !important;
  }

  .form-group {
    display: none;
  }

  .RRT__tabs {
    display: none;
  }
  .app-header {
    display: none;
  }
  .no-print {
    display: none !important;
  }
  .rc-tabs-bar {
    display: none !important;
  }
}

.text-align-center {
  text-align: center !important;
}

.badge-pill-container-grid {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .badge {
    // max-height: 1.5rem;
    width: 6rem;
    cursor: pointer;
    padding: 7px 10px;
    margin-right: 1rem;
    // border-radius: 5px;
  }
  .active-badge {
    border: 2px solid black;
  }
}

.dropdown-menu.show {
  pointer-events: all !important;
}
.RRT__showmore-list {
  z-index: 5000 !important;
}

.RRT__tab {
  // white-space: unset !important;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: fit-content !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.where-clause-date-picker {
  width: 100% !important;
  padding: 0.2rem;
  margin-top: 0.5rem;
}
.remove-outline:focus {
  outline: none;
}
.rx-toolbar-container {
  z-index: 0 !important;
}

.question-date-picker {
  // background: transparent;
  // border: 2px;
  // color: inherit;
  width: 20rem;
  padding: 0.3rem;
}

.signature-pad {
  border: 1px solid black;
  height: 200px;
}
.verticle-devider {
  border-left: 2px solid #e5e5e5;
  padding-bottom: 11px;
}

.disabled-pill {
  pointer-events: none;
  cursor: no-drop !important;
  opacity: 0.5;
}

.grid_note_alert {
  display: flex;
  overflow: hidden;
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  transition: all 2s;
}

.blue-border {
  border: 1px solid #049fdb;
  border-radius: 0.3rem;
}

.green-border {
  border: 1px solid green !important;
  border-radius: 0.3rem;
}

.edit-page-tab {
  z-index: 1 !important;
  // position: relative !important;
}

.react-datepicker-popper {
  z-index: 3000000 !important;
}

.text-time-filter-button {
  background-color: transparent !important;
  overflow: hidden;
  border: 1px solid #ced4da !important;
  color: #ced4da !important;
  width: 100% !important;

  padding: 0.3rem 0.75rem;
  white-space: nowrap;

  text-overflow: ellipsis;
}

.text-time-dropdown-menu {
  width: 300px !important;

  padding: 1rem;

  .title {
    color: rgba(0, 0, 0, 0.6);
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.expanded_column {
  &_container {
    padding: 1rem;

    table {
      margin-top: 1rem;
      border: 1px solid #e9ecef;

      th {
        color: #545cd8 !important;
        border: 1px solid #e9ecef;
      }
      td {
        border: 1px solid #e9ecef;
      }
    }
  }
}

.bg-green {
  background: #00a65a !important;
  color: rgba(255, 255, 255, 0.9);
}
.bg-yellow {
  background-color: #f39c12 !important;
  color: rgba(255, 255, 255, 0.9);
}
.bg-orange {
  background-color: #ff851b !important;
  color: rgba(255, 255, 255, 0.9);
}

.bg-red {
  background-color: #dd4b39 !important;
  color: rgba(255, 255, 255, 0.9);
}
.react-datepicker-time__input-container {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.react-datepicker-time__caption {
  text-align: centerf;
}
.react-datetimerange-picker__wrapper {
  border: 1px solid rgb(0, 0, 0, 0.2) !important;
  padding: 3px;
  border-radius: 4px;
  color: rgb(0, 0, 0, 0.6);
}

.no-border {
  border: none !important;
}

.layout-setting-row {
  text-align: center;
  background: rgb(246, 246, 246);
  border-radius: 5px;
  margin-bottom: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding: 1rem;

  .layout-setting-block {
    background: rgb(228, 237, 245);
    border-radius: 5px;
    padding: 1rem;
    border: 1px dashed rgb(188, 192, 194);
    margin: 0px;
    min-height: 15rem;
    max-height: 15rem;
    overflow: auto;
  }
}

.dashboard-chart-title {
  font-weight: 900;
  font-size: 15px;
  margin-bottom: 1rem;
}

.react-datepicker-wrapper {
  flex: 1;
}

.rti--container {
  padding: 0.4rem !important;
  margin-bottom: 1rem !important;
}
.rti--input {
  width: 100% !important;
}

.chat_infinite_scroll {
  .dropdown-item.active {
    background-color: #049fdb;
    border-radius: 8px;
  }
}

.email-sidebar {
  .dropdown-item.active {
    background-color: #30b1ff22;
    border-radius: 8px;
    color: #212529;
  }
}

.wizard-single-form-with-child-submit {
  position: absolute;
  bottom: -4rem;
  right: 0;
}

.email-pagination {
  .pagination {
    justify-content: center;
  }
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.input-dollar-icon {
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.cross-badge-sizing {
  width: 1rem;
  height: 1rem;
  top: -6px;
  left: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  i {
    width: 0.7rem;
    height: 0.7rem;
  }
}

.select__menu {
  z-index: 1000000;
}

.file-upload-field {
  max-width: 100% !important;
  padding: 3rem !important;
}

.error-input {
  border: 1px solid red !important;
  &::placeholder {
    color: red !important;
  }
}

::placeholder {
  // color: hsl(0, 0%, 70%) !important;
  color: rgba(0, 0, 0, 0.2) !important;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: hsl(0, 0%, 70%) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.radio-field-wrapper {
  .form-control {
    // padding: 5px 10px !important;
  }
  .form-check {
    margin-bottom: 5px;
    margin-left: 5px;
    // display: inline-block;
  }
}

.hide-progress-symbole {
  .react-sweet-progress-symbol {
    display: none;
  }
}

/******** Permission Block CSS ***********/

.permission-header {
  background-color: rgba(4, 159, 219, 0.1);
  font-weight: 700;
  padding: 16px 24px;
  // font-size: 16px;
}
.permission-table {
  border: 1px solid #e3e3e3;
  .permission-block {
    font-size: 12px;
    padding: 14px 36px;
  }
  .role {
    // font-size: 16px;
    font-weight: 700;
    padding: 12px;
    border-radius: 10px;
    color: #8e8e8e;
    margin-bottom: 20px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    .role-pill {
      font-size: 8px;
      padding: 4px 10px;
      margin: -25px 0 0 -10px;
    }
  }
  .active-role {
    background-color: rgba(4, 159, 219, 0.1);
    color: #049fdb;
  }
  .permission-group {
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #e3e3e3;
    &:last-child {
      border-bottom: none;
    }
    h6 {
      font-weight: 700;
      font-size: 16px;
      color: #8e8e8e;
    }
  }
}
.permission-tab-wrapper {
  .rc-tabs-nav-scroll,
  .rc-tabs-nav {
    width: 100% !important;
  }
  .rc-tabs-nav-wrap {
    padding: 0;
  }
  .rc-tabs-top {
    max-width: 100%;
    .rc-tabs-bar {
      border-bottom-color: #e3e3e3;
    }
  }
}
.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
  }
  &::-webkit-scrollbar-thumb {
    background-color: lightgrey; /* Color of the scrollbar handle */
    border-radius: 5px; /* Border radius of the scrollbar handle */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #707070; /* Color of the scrollbar handle on hover */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background color of the scrollbar track */
  }

  &::-webkit-scrollbar-corner {
    background: #f1f1f1; /* Background color of the scrollbar corners */
  }

  &::-webkit-scrollbar-button {
    display: none; /* Hide scrollbar buttons */
  }
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  z-index: 1;
  border-radius: 16px;
}

.contact-us {
  input {
    padding: 0.3rem 1rem;
    border-radius: 0.5rem;
  }
}

.img-100 {
  height: 100px !important;
}

.img-300 {
  height: 300px !important;
}

.img-w-300 {
  width: 300px !important;
}

.h-100vh {
  height: 100vh !important;
}

.px-3vw {
  padding-left: 3vw !important;
  padding-right: 3vw !important;
}

.content-widget.heading {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700 !important;
  }
}

.number-list {
  p {
    font-weight: 300 !important;
  }
  ul {
    margin-left: 15px;

    li {
      font-weight: 300;
    }
  }
}

@media (min-width: 1750px) {
  .btn.rounded-pill {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
  }
}

@media (min-width: 750px) and (max-width: 1750px) {
  .btn.rounded-pill {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (max-width: 750px) {
  .btn.rounded-pill {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}

.text-muted-light {
  color: #8e8e8e !important;
}

.active-cms-edit {
  border: 2px solid #000;
  border-width: 3px !important;
  border-radius: 5px;
  padding: 2rem !important;
  animation: blink-border 1s infinite;
  margin: 1rem;
}

@keyframes blink-border {
  0% {
    border-color: #000;
  }
  50% {
    border-color: #00000044;
  }
  100% {
    border-color: #000;
  }
}

input[type='checkbox'] {
  -ms-transform: scale(1.1); /* IE */
  -moz-transform: scale(1.1); /* FF */
  -webkit-transform: scale(1.1); /* Safari and Chrome */
  -o-transform: scale(1.1); /* Opera */
  padding: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.primary_color {
  color: #049fdb;
  label {
    color: #049fdb;
  }
  i {
    color: #049fdb;
  }
}

.btn-outline-primary {
  border-color: #049fdb !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: #049fdb !important;

  i {
    color: #049fdb !important;
  }
}
.btn-outline-primary:hover {
  background-color: #049fdb !important;
  box-shadow: none !important;
  color: #fff !important;

  i {
    color: #fff !important;
  }
}

.btn-outline-success {
  i {
    color: #28a745 !important;
  }
}

.btn-outline-success:hover {
  i {
    color: #fff !important;
  }
}

.table-template-widget thead th {
  border-color: #1e1e1e !important;
  border-bottom: none !important;
}

.table-template-widget td {
  border-color: #1e1e1e !important;
}

.tooltip-without-arrow .arrow {
  display: none !important;
}

.notes-type-table .form-group {
  margin-bottom: 0rem !important;
}

.notes-type-table input.date-picker {
  padding: 0.37rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.notes-type-table {
  .row-odd {
    background-color: rgba(0, 0, 0, 0.03) !important;
  }

  td {
    padding: 0.55rem !important;
    border: 1px solid #dee2e6 !important;
  }
  th {
    vertical-align: top !important;
    border: 1px solid #dee2e6 !important;
  }
  th.no-border-top {
    border-top: none !important;
  }
  th.no-border-bottom {
    border-bottom: none !important;
  }
}

textarea {
  min-height: 7rem;
}

.form-check {
  margin-bottom: 0.6rem;
  label {
    margin-left: 0.5rem;
  }
}

input.form-check-input {
  transform: scale(0.9);
}
.bg-info {
  background-color: #f39c12 !important;
}

.global-filter {
  .react-datepicker__input-container {
    position: relative;
  }
}
.react-datepicker__close-icon::after {
  background-color: #049fdb;
}

.badge {
  font-weight: 500 !important;
  text-transform: none !important;
}
.form-control {
  color: #212529 !important;
}

.rc-tabs-ink-bar.rc-tabs-ink-bar-animated {
  display: none !important;
}

.rc-tabs-tab-active {
  border-bottom: 1px solid #108ee9 !important;
}

.rc-tabs-nav-wrap{
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.rc-tabs-top .rc-tabs-tab-prev, .rc-tabs-top .rc-tabs-tab-next {
  top: -23px !important;
}


.tooltip {
  .arrow {
    display: none !important;
  }
}


.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select{
  margin-top: 0.5rem;
}

.pointer-event-none {
  pointer-events: none;
}

.opacity-half {
  opacity: 0.5;
}