.carousel {
  .content-wrapper {
    padding-top: 13rem;
    padding-bottom: 10rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 7rem;
    padding-right: 6rem;
    max-width: 1440px;
    width: 1440px;
    display: flex;
    // justify-content: center;
    align-items: center;
    // min-width: 1440px;
    // margin: auto

    .carousal-container {
      display: flex;

      .content {
        width: 73%;
        padding-right: 2rem;
      }
    }
  }

}
@media (min-width: 944px) and (max-width: 1200px) {
  .carousal-container {
      display: flex;

      .content {
        width: 65% !important;
        padding-right: 2rem;
      }
    }
}
@media (min-width: 420px) and (max-width: 944px) {
  .carousel .content-wrapper {
    padding: 4rem !important;
  }
  .carousal-container {
    flex-direction: column;
      .content {
        width: 95% !important;
        padding-right: 1rem;
      }

    .action-wrapper {
      justify-content: start !important;
    }
  }
}

@media (max-width: 420px) {
    .carousel .content-wrapper {
    padding: 2rem !important;
  }
  .carousal-container {
    flex-direction: column;
      .content {
        width: 100% !important;
        padding-right: 0rem;
      }

    .action-wrapper {
      justify-content: start;
    }
  }
}
